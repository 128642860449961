// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgAttachFile = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.75 5.627v8.817c0 1.741-1.275 3.291-3.009 3.458a3.33 3.33 0 0 1-3.658-3.317v-10.3c0-1.091.783-2.083 1.867-2.191 1.25-.125 2.3.85 2.3 2.075v8.75a.836.836 0 0 1-.834.833.836.836 0 0 1-.833-.833V5.627a.63.63 0 0 0-.625-.625.63.63 0 0 0-.625.625v7.175c0 1.092.783 2.083 1.867 2.192 1.25.125 2.3-.85 2.3-2.075V4.31C12.5 2.57 11.225 1.02 9.49.852a3.33 3.33 0 0 0-3.658 3.317v10.225c0 2.391 1.75 4.533 4.133 4.758A4.59 4.59 0 0 0 15 14.585V5.627a.63.63 0 0 0-.625-.625.63.63 0 0 0-.625.625"
    />
  </svg>
);
export const AttachFileIcon = forwardRef(SvgAttachFile);
